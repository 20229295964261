import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserTaskApiService } from '../../../../services/api/user-tasks/user-tasks-api.service';
import { MessageService } from 'primeng';

@Component({
  selector: 'dispatch-notify-clients-reminder',
  templateUrl: './dispatch-notify-clients-reminder.component.html',
  styleUrls: ['./dispatch-notify-clients-reminder.component.scss']
})
export class DispatchNotifyClientsReminderComponent implements OnInit {

  fileId: number;

  constructor(public apiService: UserTaskApiService,
    private route: ActivatedRoute,
     private messageService: MessageService,
) { }

  ngOnInit(): void {
    this.getRouteParams();
  }

  public DispatchNotifyClientReminder() {
    try {
      let resp = this.apiService.DispatchNotifyClientReminder(this.fileId);      
      if (!resp){ this.toastMessage('error', 'Failed to get approve / reject admin transaction user task. Please contact support', '');
      }
    }
    catch (error) {
      this.toastMessage('error', error, 'Could not complete task. Please contact support');
    }
  }

private getRouteParams() { var fileId = this.route.snapshot.paramMap.get('fileId'); this.fileId = Number(fileId) || 0; }
public toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }); }
}
